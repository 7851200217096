import { Vue, Component, Prop, Ref } from "vue-property-decorator";

type Item = Record<string, unknown>;

@Component
export default class InfiniteList extends Vue {
  @Ref("loader") private readonly loaderRef!: HTMLDivElement;

  @Prop({ type: String, default: "id" }) private readonly itemId!: string;
  @Prop({ type: String, default: "$vuetify.noDataText" })
  private readonly noDataText!: string;

  @Prop({ type: Number, default: 1 }) private readonly page!: number;
  @Prop({ type: Number, default: 200 })
  private readonly offset!: number;
  @Prop({ type: Boolean, default: false })
  private readonly loading!: boolean;
  @Prop({ type: Array, required: true }) private readonly items!: Item[];
  @Prop({ type: Number, default: 5 }) private readonly itemsPerPage!: number;
  @Prop({ type: Number }) private readonly serverItemsLength?: number;

  private localPage = 1;

  private get usedServerItemsLength() {
    return typeof this.serverItemsLength === "number";
  }

  private get showedSkeleton() {
    return this.loading && this.items.length === 0;
  }

  private get totalItemsLength() {
    return this.serverItemsLength ?? this.items.length;
  }

  private get localItems() {
    if (this.usedServerItemsLength) {
      return this.items;
    }

    return this.items.slice(0, this.itemsPerPage * this.localPage);
  }

  private get hasMore() {
    return this.totalItemsLength > this.localItems.length;
  }

  private get showLoader() {
    return this.hasMore || this.loading;
  }

  private created() {
    this.$watch(
      () => {
        return this.localPage;
      },
      (page) => {
        this.$emit("update:page", page);
      }
    );

    this.$watch(
      () => this.page,
      (page) => {
        this.localPage = page;
      },
      {
        immediate: true,
      }
    );
  }

  private mounted() {
    let loaderObserverAnimationFrameId = 0;
    let observeTimeoutId = 0;

    const loaderObserver = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        if (!isIntersecting || !this.hasMore) return;

        window.cancelAnimationFrame(loaderObserverAnimationFrameId);
        loaderObserver.unobserve(this.loaderRef);

        if (this.usedServerItemsLength) {
          this.localPage++;
        } else {
          this.localPage++;
          loaderObserverAnimationFrameId = window.requestAnimationFrame(() => {
            loaderObserver.observe(this.loaderRef);
          });
        }
      },
      {
        rootMargin: `0px 0px ${this.offset}px 0px`,
        threshold: 1,
      }
    );

    this.$watch(
      () => {
        return this.items.length;
      },
      (itemsLength) => {
        window.clearTimeout(observeTimeoutId);

        this.localPage = Math.max(
          Math.min(Math.ceil(itemsLength / this.itemsPerPage), this.localPage),
          1
        );

        if (itemsLength) {
          observeTimeoutId = window.setTimeout(() => {
            loaderObserver.observe(this.loaderRef);
          }, 300);
        } else {
          loaderObserver.unobserve(this.loaderRef);
        }
      },
      {
        immediate: true,
      }
    );

    this.$once("hook:beforeDestroy", () => {
      loaderObserver.disconnect();
      window.cancelAnimationFrame(loaderObserverAnimationFrameId);
      window.clearTimeout(observeTimeoutId);
    });
  }
}
